import React from "react";
import FacebookLogo from "../img/facebook_icon.png";

export default () => (
  <div style={{ paddingTop: "30px" }}>
    <footer className="footer">
      <div className="content columns">
        <p className="column is-two-sixths">
          <b>Adresse</b>
          <br />
          437 chemin des quilles <br />
          Résidence NEPTUNE II 34200 Sète
          <br />
          VENISSIEUX
        </p>
        <p className="column is-two-sixths">
          <b>Adresse postale</b>
          <br />
          15 BD Joliot Curie <br />
          Résidence Horizon Marin 34200 Sète
          <br />
          VENISSIEUX
        </p>
        <div className="column is-one-sixths"></div>
        <p className="column is-two-sixths">
          <a href="https://www.facebook.com/anne.diaz.98871">
            <img
              style={{ maxHeight: "80px", float: "left", padding: "10px" }}
              src={FacebookLogo}
              alt="lien au page facebook"
            />
          </a>
          SIRET - 520 190 539 00012 - APE 9604Z
          <br />
          Gsm. 06 89 61 15 09 - Tél : 09 50 05 71 65
          <br />
          Mail : diazanne@hotmail.fr
        </p>
      </div>
    </footer>
  </div>
);
